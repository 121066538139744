import { useCallback, useContext, useEffect, useState } from "react";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useTranslation } from "react-i18next";

export default function Activities({toggleVideoStoryModal}) {
  const { t } = useTranslation();

  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );
  const targetUnit = reservation.entries[0].units[0];
  const targetRoom = reservation.entries[0].roomId;
  const { activities } = useContext(ReservationDataContext);
  const { nextStep } = useContext(ReservationNavigationContext);
  const [targetUnitActivities, setTargetUnitActivities] = useState([]);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('English')

  useEffect(() => {
    if(i18n.language === 'hr'){
      setLanguage('Hrvatski')
    } else {
      setLanguage('English')
    }
  }, [i18n.language])

  const checkIfActivityContainsTargetUnit = useCallback(
    (activities) => {
      return activities.filter(
        (activity) =>
          activity.units.filter((e) => e.id === targetUnit).length > 0 ||
          activity.units.length === 0
      );
    },
    [targetUnit]
  );

  const checkIfActivityContainsUnitInTargetRoom = useCallback(
    (activities) => {
      return activities.filter(
        (activity) =>
          activity.units.filter((e) => e.rooms.includes(targetRoom)).length >
            0 || activity.units.length === 0
      );
    },
    [targetRoom]
  );

  useEffect(() => {
    var targetActivities =
      targetUnit === undefined
        ? checkIfActivityContainsUnitInTargetRoom(activities)
        : checkIfActivityContainsTargetUnit(activities);

    setTargetUnitActivities(targetActivities);
  }, [
    activities,
    targetUnit,
    checkIfActivityContainsTargetUnit,
    checkIfActivityContainsUnitInTargetRoom,
  ]);

  const handleActivityChange = (e) => {
    var newEntry = reservation.entries;
    var wantedActivity = activities.filter((x) => x.id === e.target.id);

    if (wantedActivity.length === 0) return;
    newEntry[0].locationActivityId = e.target.id;
    newEntry[0].durationInMinutes = wantedActivity[0].durationInMinutes;

    onReservationChange({ entries: newEntry });
    nextStep();
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <h1 className="large-p">{t("ActivitiesStep.Title")}</h1>

      <div className="page" style={{ maxWidth: "30rem", width: '80%' }}>
        <div className="wrapper">
          {targetUnitActivities.length !== 0
            ? targetUnitActivities.map((activity) => {
                return (
                  <div key={activity.name} className="activity">
                    {activity.imagePath !== "" ? 
                      <div className="activity-image">
                        <img alt="activity" src={activity.imagePath} />
                      </div> 
                    : null}
                    
                    {/* 
                    <h3 className="activity-title">{activity.name}</h3>
                    */}
                    <h3 className="activity-title">
                      {activity.nameTranslations.find(entry => entry.language === language)?.text || 
                      activity.nameTranslations.find(entry => entry.language === 'English')?.text}
                    </h3>


                    {activity.description !== "" && activity.description !== undefined ? 
                      <p className="activity-text">
                        {activity.description}
                      </p>
                    : null}

                    <div className="activity-footer">
                      <button
                        id={activity.id}
                        className="activity-button"
                        onClick={handleActivityChange}
                      >
                        {t("ActivitiesStep.BookNow")}
                      </button>
                    </div>
                    {activity.videoPath !== "" ? 
                      <p className="activity-video" onClick={() => toggleVideoStoryModal(activity)}>or watch video</p>
                    : null}
                  </div>
                  /*
                  <OptionPicker
                    key={activity.id}
                    name="activities"
                    description={activity.description}
                    identity={activity.id}
                    text={activity.name}
                    handleChange={handleActivityChange}
                  />*/
                );
              })
            : activities.map((activity) => {
                return (
                  <div key={activity.name} className="activity">
                    <div className="activity-image">
                      {activity.imagePath !== "" ? <img alt="activity" src={activity.imagePath} /> : null}
                    </div>
                    <h3 className="activity-title">{activity.name}</h3>
                    <p className="activity-text">
                      {activity.description}
                    </p>
                    <div className="activity-footer">
                      <button
                        id={activity.id}
                        className="activity-button"
                        onClick={handleActivityChange}
                      >
                        {t("Locations.BookThisLocation")}
                      </button>
                    </div>
                    {activity.videoPath !== "" ? 
                      <p className="activity-video" onClick={() => toggleVideoStoryModal(activity)}>or watch video</p>
                    : null}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
